// frontend/src/main.ts

interface ApiResponse {
  sessionId: string;
}

interface FetchVideoResponse {
  status: 'Processing' | 'Completed' | 'Error';
  videoUrl?: string;
  thumbnailUrl?: string;
  error?: string;
}

document.addEventListener('DOMContentLoaded', () => {
  const urlInput = document.getElementById('urlInput') as HTMLInputElement;
  const submitBtn = document.getElementById('submitBtn') as HTMLButtonElement;
  const spinner = document.getElementById('spinner') as HTMLDivElement;
  const result = document.getElementById('result') as HTMLDivElement;
  const thumbnail = document.getElementById('thumbnail') as HTMLImageElement;
  const videoLink = document.getElementById('videoLink') as HTMLAnchorElement;
  const thumbnailLink = document.getElementById('thumbnailLink') as HTMLAnchorElement;

  // Add this new event listener
  urlInput.addEventListener('keypress', (event) => {
    if (event.key === 'Enter') {
      event.preventDefault(); // Prevent the default form submission
      submitBtn.click(); // Trigger the click event on the submit button
    }
  });

  let sessionId: string | null = null;

const BACKEND_URL = '';

  submitBtn.addEventListener('click', async () => {
    const url = urlInput.value.trim();
    if (!url) return;

    console.log('Submitting URL:', url);

    submitBtn.textContent = 'Processing';
    submitBtn.style.backgroundColor = 'red';
    spinner.style.display = 'inline-block';
    result.style.display = 'none';

    try {
      console.log('Sending POST request to:', `${BACKEND_URL}/api/process_link`);
      const response = await fetch(`/api/process_link`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ url })
      });

      console.log('Received response:', response);
      
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      
      const data: ApiResponse = await response.json();
      console.log('Parsed response data:', data);
      sessionId = data.sessionId;
      checkResults();
    } catch (error) {
      console.error('Error:', error);
      submitBtn.textContent = 'Submit';
      submitBtn.style.backgroundColor = 'blue';
      spinner.style.display = 'none';
      alert('An error occurred while processing the URL. Please try again.');
    }
  });

  const MAX_ATTEMPTS = 60; // This will run for 5 minutes (60 * 5 seconds)
  let attempts = 0;

  const loadImage = (url: string): Promise<void> => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => resolve();
      img.onerror = (e) => reject(new Error(`Failed to load image: ${url}, error: ${e}`));
      img.src = url;
    });
  };


  const checkResults = async () => {
    if (!sessionId || attempts >= MAX_ATTEMPTS) return;

    try {
      console.log(`Checking results for session: ${sessionId} (Attempt ${attempts + 1}/${MAX_ATTEMPTS})`);
      const response = await fetch(`/api/fetch_video?sessionId=${sessionId}`);
      console.log('Fetch video response:', response);
      
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      
      const data: FetchVideoResponse = await response.json();
      console.log('Fetch video data:', data);

      if (data.status === 'Processing') {
        attempts++;
        setTimeout(checkResults, 5000);
      } else if (data.status === 'Completed' && data.videoUrl && data.thumbnailUrl) {
        console.log('Video processing completed');
        console.log('Thumbnail URL:', `${data.thumbnailUrl}`);
        console.log('Video URL:', `${data.videoUrl}`);
        
        // Add a cache-busting query parameter
        const cacheBuster = Date.now();
        thumbnail.src = `${data.thumbnailUrl}?cb=${cacheBuster}`;
        
        videoLink.href = data.videoUrl;
        videoLink.download = data.videoUrl.split('/').pop() || 'video.mp4';
        thumbnailLink.href = data.thumbnailUrl;
        thumbnailLink.download = data.thumbnailUrl.split('/').pop() || 'thumbnail.jpg';

        // Force a reload of the image
        thumbnail.onload = () => {
          console.log('Thumbnail loaded successfully');
  };
        thumbnail.onerror = (e) => {
          console.error('Error loading thumbnail:', e);
        };

        result.style.display = 'block';
        submitBtn.textContent = 'Submit';
        submitBtn.style.backgroundColor = 'blue';
        spinner.style.display = 'none';
      } else if (data.status === 'Error') {
        console.error('Error processing video:', data.error);
        submitBtn.textContent = 'Submit';
        submitBtn.style.backgroundColor = 'blue';
        spinner.style.display = 'none';
        alert(`Error processing video: ${data.error}`);
      } else {
        console.error('Unexpected response:', data);
        submitBtn.textContent = 'Submit';
        submitBtn.style.backgroundColor = 'blue';
        spinner.style.display = 'none';
        alert('An unexpected error occurred. Please try again.');
      }
    } catch (error) {
      console.error('Error in checkResults:', error);
      attempts++;
      if (attempts < MAX_ATTEMPTS) {
        setTimeout(checkResults, 5000);
      } else {
        submitBtn.textContent = 'Submit';
        submitBtn.style.backgroundColor = 'blue';
        spinner.style.display = 'none';
        alert('Failed to fetch video after multiple attempts. Please try again.');
      }
    }
  };

  // Start checking results immediately after getting the sessionId
  // checkResults();
});
